import axios from 'axios';

const baseURL = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/tblTVroUgAbyIFCCG`;

const airtableAPI = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_PAT}`,
  },
});

export const fetchEventData = async () => {
  let allRecords = [];
  let offset = null; // Start without an offset

  try {
    do {
      const response = await airtableAPI.get('', {
        params: { offset }, // Pass offset as a query parameter if it exists
      });

      const newRecords = response.data.records.map(record => {
        const atcoField = record.fields.ATCO || ''; // Default to empty string if undefined
        return {
          date: record.fields.Date,
          departures: record.fields.Departures,
          arrivals: record.fields.Arrivals,
          config: record.fields.Config,
          atco: atcoField.split(',').map(Number), // Convert ATCO to an array of numbers
        };
      });

      allRecords = [...allRecords, ...newRecords]; // Append new records to allRecords

      offset = response.data.offset; // Get the next offset from the response, if available
    } while (offset); // Continue fetching until there's no more offset

    return allRecords;
  } catch (error) {
    console.error('Error fetching data from Airtable', error);
    return []; // Return an empty array in case of error
  }
};
